import Link from "next/link";
import { FaWhatsapp } from "react-icons/fa";
const WhatsapChatBot = () => {
    return (
        <div>
            <Link href={"https://wa.me/919318192605"} className="btn-whatsapp-pulse" target="_blank" aria-label="Open WhatsApp">
                <FaWhatsapp
                        style={{
                            color: "white",
                            height: "40px",
                            width: "40px",
                            position: 'absolute'
                        }}
                    />
                {/* </p> */}
            </Link>
        </div>
    );
}
export default WhatsapChatBot;