import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Head from "next/head";
import { useRouter } from "next/router";
import WhatsapChatBot from "./WhatsapChatBot/WhtsapChatBot";

function Layout({ children }) {
    const router = useRouter();
    if (router.pathname != "/bank-details" && router.pathname != "/cancellation" && router.pathname != "/shushilsig/e-mail" && router.pathname != "/simranthakursig")
        return (
            <>
                <Header />
                {children}
                <Footer />
                <WhatsapChatBot />
            </>
        )
    else {
        return (
            <>
                {children}
            </>
        )
    }
    // return (
    //     <>
    //         <Head>
    //             <link rel="icon" href="/favicon.svg" />
    //             <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    //         </Head>
    //         <Header />
    //         {children}
    //         <Footer />
    //     </>
    // );
}

export default Layout;
